<template>
    <div class="page-content">
        <div class="park-form">
            <el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
                <el-form-item label="账号" prop="username">
                    <el-input v-model="searchForm.username" style="width: 220px;" clearable
                              placeholder="请填写账号"></el-input>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-right: auto;">
                    <div class="fill-btn" @click="getAdminList">查询</div>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
                    <div class="fill-btn" @click="handShowAdd">新增</div>
                </el-form-item>
            </el-form>
        </div>
        <div class="table-content">
            <div class="table-top">
                <div class="table-top-text">
                    <!--已选择{{selectData.length}}项-->
                </div>
            </div>
            <el-table :data="tableData" stripe
                      v-loading="loading"
                      @selection-change="handleSelectionChange"
                      cell-class-name="text-center"
                      header-cell-class-name="header-row-class-name">
                <el-table-column prop="username" label="账号" min-width="120px"></el-table-column>
                <el-table-column prop="nickname" label="昵称" min-width="120px"></el-table-column>
                <!--<el-table-column prop="nickname" label="状态" min-width="120px" :formatter="statusTable"></el-table-column>-->
                <el-table-column width="200 " label="状态">
                    <template slot-scope="{row}">
                        <div class="btn-content">
                            <el-button v-if="row.status == 1" type="primary" round>正常</el-button>
                            <el-button v-if="row.status == 2" type="warning" round>停用</el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" min-width="160px" label="创建时间"></el-table-column>
                <el-table-column width="200" fixed="right">
                    <template slot-scope="{row}">
                        <div class="btn-content">
                            <el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
                                       @click="handEditShow(row)"></el-button>
                            <el-button class="el-icon-delete cur-p" type="primary" size="mini"
                                       @click="adminDel(row)"></el-button>
                            <!--<el-button class="el-icon cur-p" type="success" size="mini"-->
                                       <!--@click="handGiveAuth(row)">赋权限</el-button>-->

                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-content">
                <el-pagination
                        background
                        :current-page.sync="searchForm.page"
                        :page-size="searchForm.limit"
                        @current-change="searchForm.page=$event"
                        @size-change="handleSizeChange"
                        layout="total, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </div>
        <HgDialog title="新增人员" :width="500" :maskClose="false" v-model="showAdd">
            <el-form class="dialog-form-content" :model="addForm" :rules="getRules" ref="addForm" label-width="120px">
                <el-form-item label="账号" prop="username" required>
                    <el-input v-model="addForm.username" style="width: 220px;" placeholder="请填写账号不得超过13位"></el-input>
                </el-form-item>
                <el-form-item label="昵称" prop="nickname" required>
                    <el-input v-model="addForm.nickname" style="width: 220px;" placeholder="请填写昵称不得超过13位"></el-input>
                </el-form-item>

                <el-form-item label="密码" prop="password" required>
                    <el-input type="text" v-model="addForm.password" style="width: 220px;"
                              placeholder="请填写密码"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="status" required>
                    <el-select v-model="addForm.status"   placeholder="请选择状态">
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="停用" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :loading="editing" @click="adminAdd">保存</el-button>
                </el-form-item>
            </el-form>
        </HgDialog>
        <HgDialog title="编辑账号" :width="500" :maskClose="false" v-model="showEdit">
            <el-form class="dialog-form-content" :model="editForm" :rules="getRules" ref="editForm" label-width="120px">

                <!--<el-form-item label="头像">-->
                    <!--<el-upload-->
                            <!--class="avatar-uploader"-->
                            <!--action="/admin/upload"-->
                            <!--:show-file-list="false"-->
                            <!--:on-success="handleAvatarSuccess"-->
                            <!--:before-upload="beforeAvatarUpload">-->
                        <!--<img v-if="editForm.head" :src="editForm.head" class="avatar">-->
                        <!--<i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
                    <!--</el-upload>-->
                <!--</el-form-item>-->

                <el-form-item label="权限" prop="role_ids" required>
                    <el-select v-model="editForm.role_ids"  multiple>
                        <el-option v-for="(item,index) in roleList" :key="index" :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="昵称" prop="nickname" required>
                    <el-input v-model="editForm.nickname" style="width: 220px;" placeholder="请填写昵称"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input type="text" v-model="editForm.password" style="width: 220px;"
                              placeholder="不修改不填密码"></el-input>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select v-model="editForm.status" placeholder="请选择状态">
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="停用" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :loading="editing" @click="adminEdit">保存</el-button>
                </el-form-item>
            </el-form>
        </HgDialog>
        <HgDialog title="修改密码" :width="500" :maskClose="false" v-model="showEditPsd">
            <el-form class="dialog-form-content" :model="editForm" :rules="getRules" ref="editForm" label-width="120px">
                <el-form-item label="新密码" prop="newPassword" required>
                    <el-input v-model="editForm.newPassword" placeholder="请填写新密码"></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="fill-btn" :loading="editing">保存</div>
                </el-form-item>
            </el-form>
        </HgDialog>

        <HgDialog title="赋权限" :width="500" :maskClose="false" v-model="giveAuth">
            <div class="dialog-form-content">
                <el-tree
                        ref="tree"
                        node-key="id"
                        style="margin-bottom: 20px;"
                        :default-checked-keys="menuIds"
                        :check-strictly="false"
                        :props="props"
                        :data="authList"
                        show-checkbox
                        @check-change="handleCheckChange">
                </el-tree>
                <el-button type="primary" :loading="editing" @click="setRolePermission">保存</el-button>
            </div>
        </HgDialog>
    </div>
</template>

<script>
    import {delToast, formVerify} from './../../utlis/decorators'

    export default {
        name: "tenant_account",
        props: {
            parkId: {
                type: [Number, String],
                default: null
            },
        },
        data() {
            return {
                loading: false,
                currentTop: 0,
                total: 0,
                showAdd: false,
                showEdit: false,
                showEditPsd: false,
                editing: false,
                giveAuth: false,
                searchForm: {
                    page: 1,
                    limit: 10,
                    username: ''
                },
                props:{
                    label: 'name',
                    children: 'children'
                },
                menuIds:[],
                authList: [],
                addForm:{
					nickname: '',
					username: '',
					password: '',
					status:''
				},
                form: {
                    nickname: ''
                },
                editForm: {
                    role_ids: [],
                    nickname: '',
                    username: '',
                    password: '',
                    status:''
                },
                currentId: '',
                tableData: [],
                roleList: [],
                selectData: [],
            }
        },
        created() {
            this.getRoleList();
            this.getAdminList();
            this.getPermissionList();
        },
        computed: {
            getRules() {
                return {
                    role_ids: [{required: true, message: `角色不能为空`, trigger: ['blur', 'change']}],
                    nickname: [{required: true, message: `昵称不能为空`, trigger: ['blur', 'change']}],
                    status: [{required: true, message: `状态不能为空`, trigger: ['blur', 'change']}],

                }
            }
        },
        watch: {
            'searchForm.page'() {
                this.getAdminList();
            },
        },
        methods: {
            //头像
            handleAvatarSuccess(res, file) {

                this.editForm.head = res.url;
                this.addForm.head = res.url;
                console.log(this.addForm.head);
            },
            handleCheckChange(){
                this.menu_ids=this.$refs.tree.getCheckedKeys();

            },
            /**
             * 显示赋予权限
             * @param id
             */
            handGiveAuth({id,menu}){
                this.currentId = id;
                this.menuIds=[];


                console.log(menu);
                menu.forEach((item, index) => {
                    this.menuIds.push(item)
                });
                this.menu_ids =  this.menuIds;
                console.log( '提交'+this.menu_ids);
                this.giveAuth = true;
            },

            beforeAvatarUpload(file) {
                // const isJPG = file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;

                // if (!isJPG) {
                //     this.$message.error('上传头像图片只能是 JPG,png 格式!');
                // }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isLt2M;
            },
            // 翻页
            handShowAdd() {
                try {
                	this.showAdd = true;
					for (let i in this.addForm) {
						this.addForm[i] = '';
					}
                	this.$refs['addForm'].resetFields();
                } catch {}
            },
            handEditShow({role, nickname, account, id, status}) {
                let roles = [];
                role.forEach((item, index) => {
                    roles.push(item.role_id)
                });

                this.editForm.role_ids = roles;
                this.editForm.nickname = nickname;
                this.editForm.account = account;
                this.editForm.password = '';
                this.editForm.status = status.toString();
                this.currentId = id;
                this.showEdit = true;
            },
            /**
             * 获取权限列表
             * @returns {Promise<void>}
             */
            async getPermissionList(){
                let [e, data] = await this.$api.menuListAll();
                if(e) return;
                if(data.code === 200){
                    this.authList = data.data;
                }
            },
            //页面数量改变
            handleSizeChange(val) {
                this.searchForm.page = 1;
                this.searchForm.limit = val;
            },
            handleSelectionChange(val) {
                this.selectData = val;
            },
            //获取角色列表
            async getRoleList() {
                let [e, data] = await this.$api.getRoleLists();
                if (e) return;
                if (data.code === 200) {
                    this.roleList = data.data;
                }
            },
            //获取管理员列表
            async getAdminList() {
                this.loading = true;
                let [e, data] = await this.$api.adminList(this.searchForm);
                this.loading = false;
                if (e) return;
                if (data.code === 200) {
                    this.tableData = data.data.data;
                    this.total = data.data.total;
                }
            },
            /**
             * 赋予权限
             * @returns {Promise<void>}
             */
            async setRolePermission(){
                this.editing = true;
                let [e, data] = await this.$api.saveRoleMenu({
                    id: this.currentId,
                    menu_ids:this.menu_ids
                });
                this.editing = false;
                if(e) return;
                if(data.code === 200){
                    this.getAdminList();
                    this.giveAuth = false;
                    this.$message.success('保存成功')
                }else {
                    this.$message.warning(`${data.msg}`)
                }
            },
            @delToast()
            async adminDel({id}) {
                let [e, data] = await this.$api.delAdmin({id});
                if (e) return;
                if (data.code === 200) {
                    this.getAdminList();
                    this.$message.success('删除成功')
                } else {
                    this.$message.warning(`${data.msg}`)
                }
            },
            @formVerify('addForm')
            async adminAdd() {
                this.editing = true;
                let [e, data] = await this.$api.createAdmin(this.addForm);
                if (e){this.editing = false;return};
                if (data.code === 200) {
                    this.showAdd = false;
                    this.getAdminList();
                    this.$message.success('添加成功')
					this.editing = false;
                } else {
                    this.$message.warning(`${data.msg}`)
					this.editing = false;
                }
            },
            //账号编辑
            @formVerify('editForm')
            async adminEdit() {
                this.editing = true;
                let [e, data] = await this.$api.saveAdmin({
                    id: this.currentId,
                    ...this.editForm
                });
                if (e){this.editing = false;return};
                if (data.code === 200) {
                    this.showEdit = false;
                    this.getAdminList();
                    this.$message.success('保存成功')
					this.editing = false;
                } else {
                    this.$message.warning(`${data.msg}`)
					this.editing = false;
                }
            }
        },
    }
</script>
<style scoped lang="less">
    .page-content {
        width: calc(~'100% - 60px');
        margin: 26px 30px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        padding: 20px 30px;

        .park-form {
            border-bottom: 1px solid #efefef;
        }

        .table-content {
            padding-top: 20px;

            .table-top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .table-top-text {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(35, 39, 52, 1);
                }

                .table-top-btn {
                }
            }
        }
    }

    .btn-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .iconfont {
            font-size: 20px;
            color: rgb(190, 199, 213);
            margin: 0 10px;
            cursor: pointer;
        }

        .icon-shanchu {
            font-size: 24px;
        }
    }

    .box-shadow {
        width: calc(~'100% + 40px');
        height: 50px;
        position: absolute;
        top: 0;
        left: -20px;
        z-index: 10;
        box-shadow: 0 0 4px #b4b4b4;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
